@import "../../Style/Variable/Variable.scss";
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-title {
  @include font($font-family, "font_20", normal, $color-gray);
}
.ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ant-modal-body label {
  @include font($font-family, "font_13", medium2, $color-gray);
}

.modal-close {
  right: 20px;
  top: 15px;
}
.modal-close .confirm-box {
  width: 350px;
  background: rgb(255, 255, 255);
  right: 0;
  z-index: 10000;
}
.ant-modal-close {
  display: none !important;
}
