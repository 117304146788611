@import "../../Style/Variable/Variable.scss";

.my-account-heading{
    border-bottom: none ;
    padding-bottom: 20px;
    color: $colo-black1;
};

 .reset_password label{
    @include font($font-family, "font_13", medium, $color-lightgray);
}
 .reset_password input, .reset_password input::placeholder{
    @include font($font-family, "font_12", medium, $color-lightgray !important);
}
.my-account input:checked{
    border: none !important;
    box-shadow: none !important;
}
.update_header{
    position: fixed;
    z-index: 2;
    width: 100%;
}
.heading {
    display: none;
}
.reset_password{
    padding-top: 100px;
}

@media(max-width:768px){
    .label-hide{
        display: none;
    }
}


.login-back{
    @include font($font-family, "font_10", medium, $color-blue);
}