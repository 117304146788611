@import "../../Style/Variable/Variable.scss";

.my-account-heading{
    border-bottom: 2px solid $color-lightgray !important;
    padding-bottom: 20px;
    color: $colo-black1;
};



.ant-result{
    padding:50PX 20px 20px;
}

.my-account label{
    @include font($font-family, "font_12", medium, $colo-black1);
}
@media(max-width:768px){
    .label-hide{
        display: none;
    }
}

.ant-result-success .ant-result-icon > .anticon{
    color:$color-success2 ;
}
.ant-result-icon{
    margin-bottom: 0;
}
.ant-result-title{
    @include font($font-family, "font_16", medium, $color-success2);
}
