@import "../../../../../style/Variable/Variable.scss";
.property-add-icon {
	color: #307bf6;
	border: 1px solid #307bf6;
	width: 22px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.order-hr {
	height: 1px !important;
}

	// .add-property{
	// 	background: none !important;
		
	// 	}