@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.resend-btn {
  border: none;
  background-color: "none";
  padding: 0;
  border: 1px solid "none";
  border-radius: 7px;
  
  font-size: 13px;
  color: #092F50;
  font-weight: 600;
}
.bg-primary{
  background-color: #222b54 !important;
}

.login-back {
  
  font-size: 10px;
  color: #EFF8FF;
  font-weight: 600;
}/*# sourceMappingURL=ForgotPassword.css.map */