/* styles.css */
.custom-select {
    border-bottom: 2px solid #c6ced4;
    /* Add a blue bottom border */
    /* border: 2px solid red; */
    transition: border-color 0.3s;
    border-radius: 2px;
    /* Smooth transition for border color */
    display: flex;
    /* Use flexbox for alignment */
    flex-direction: column;
    /* Stack elements vertically */
    justify-content: end;
  }
  
  .ant-select-show-search:where(.css-dev-only-do-not-override-k83k30).ant-select:not(:where(.css-dev-only-do-not-override-k83k30).ant-select-customize-input) .ant-select-selector {
      cursor: text;
      /* border: 2px solid green; */
      margin-bottom: 3px;
  }
  
  :where(.css-dev-only-do-not-override-k83k30).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
      border: none;
      border-inline-end-width: undefinedpx!important;
      font-size:small;
      font-weight: 600;
      color: black;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-k83k30).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      border: none;
      box-shadow:none;
      border-inline-end-width: undefinedpx!important;
      outline: 0;
      /* border: 2px solid red; */
      font-size:small;
      font-weight: 600;
  }
  
  
  :where(.css-dev-only-do-not-override-k83k30).ant-select:not(:where(.css-dev-only-do-not-override-k83k30).ant-select-customize-input) .ant-select-selector {
      position: relative;
      border: black;
      /* transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); */
      font-size:small;
      font-weight: 600;
  }
  
  :where(.css-dev-only-do-not-override-k83k30).ant-select-multiple .ant-select-selection-overflow {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      flex: 1 1 auto;
  }
  
  /* Additional styles for alignment */
  .form-field {
    display: flex;
    /* Use flexbox for alignment */
    flex-direction: column;
    /* Stack elements vertically */
    justify-content: end;
    border: 2px solid black;
  }
  
  .form-field label {
    margin-bottom: 0px;
    /* Space between label and select */
  }
  
  .custom-label {
    font-size: 1rem; /* Adjust font size as needed */
    font-weight: bold; /* Make the label bold */
    color: #333; /* Change the text color */
    margin-bottom: 0.5rem; /* Space below the label */
}

.required {
    color: red; /* Color for the asterisk */
    margin-left: 0.2rem; /* Space between label and asterisk */
}
  
  
  select:focus,
  select:active,
  select[focused=true] {
    border-bottom-color: #40a9ff;
    /* box-shadow: 0 0 0 2px rgba(24, 144, 255, .2); */
    outline: 0;
  }
  
  .alertIcon {
    padding-right: 0.17647rem;
    top: 0.17647rem;
  }
  
  .disable-input select:focus,
  .disable-input select:active,
  .disable-input input[focused=true] {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 2px solid #CACACA !important;
  }
  
  .disable-input input {
    cursor: not-allowed;
  }
  
  .form-control1:disabled,
  .form-control1[readonly] {
    background: none !important;
    opacity: 1;
  }
  
  select.form-control:disabled, select.form-control {
    background: none !important;
    opacity: 1;
  }/*# sourceMappingURL=AppSelect.css.map */
  .app-search-select .ant-select-selector{
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-bottom: 2px solid #d9d9d9 !important;
    border-radius: 0px !important;
   }
   .app-search-select:focus .ant-select-selector:focus{
    box-shadow: none !important;
    border-bottom: 3px solid #4096ff !important;
   }
   #root + div + .ant-select-dropdown {
    display: none;
  }

.custom-select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: border-color 0.3s; /* Smooth transition for border color */
  border-radius: 0.75em;
}

.custom-select-wrapper.focused {
  border-bottom: 2px solid #4096ff; /* Change to your desired color on focus */
}
