@import "../../Style/Variable/Variable.scss";
.title-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.ant-tabs-nav-wrap {
	background-color: $color-light-blue;
	width: 100%;
	border-radius: 2px;
	padding: 0.2rem 0.7rem;
	box-shadow: 0px 3px 6px $color-shadow !important;
	@include font($font-family, "font_13", medium,$color-gray);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
	padding: 4px;

}
.ant-tabs-tab-btn{
	font-size: 12px;
}