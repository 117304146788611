@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.ant-table-filter-trigger {
  left: -47% !important;
  color: #7CFFA7;
  width: 25px;
  z-index: 10;
  position: relative;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.ant-table-filter-trigger:hover {
  color: #7CFFA7;
}/*# sourceMappingURL=label.css.map */