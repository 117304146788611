@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.file .ant-upload {
  width: 100% !important;
  min-width: 170px !important;
  margin: auto;
}

.file .ant-upload-list-picture .ant-upload-list-item, .file .ant-upload-list-picture-card .ant-upload-list-item {
  height: 55px !important;
  width: 10px !important;
}

.file .file-preview {
  width: 26px;
  height: 26px;
}

.file .filename {
  
  font-size: 10px;
  color: #4F4F4F !important;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.file .file-button:hover {
  background-color: #FFFFFF;
}

.file .close-file {
  background: rgb(249, 185, 185);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  color: #fff !important;
  font-size: 10px;
}

.file .file-flag {
  margin-top: -2.5rem !important;
}/*# sourceMappingURL=AppFIle.css.map */