@import "../../Style/Variable/Variable.scss";

.global-search input:active {
  border: none !important;
}
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px;
  @include font($font-family, "font_11", medium, $color-gray3);
}
.ant-table-thead > tr > th {
  padding: 10px 5px;
}
.ant-table-thead > tr > th {
  @include font($font-family, "font_12", medium, $color-white);
}
.search-table .ant-table-thead > tr > th {
  background-color: #dadada !important;
  color: #000;
}

.ant-spin-nested-loading > div {
  padding: 0px !important;
}
.app-table .ant-table-wrapper {
  box-shadow: 0px 3px 6px $color-shadow !important;
  background-color: $color-white !important;
}
.editable-row .ant-form-item-explain {
  top: 0% !important;
}
.ant-table-filter-dropdown .ant-space-item:first-child button {
  display: flex;
  align-items: center;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 2px;
  height: 100%;
  background-color: #67ada4 !important;
}

.gray-table-col
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 1px;

  height: 1.6em;
  background-color: #dadada !important;
}

.non-seprate
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
  height: 1.6em;
  background-color: #dadada !important;
}

tr.active {
  background: #1890ff54 !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #1890ff1c;
}

.ant-table-filter-trigger.active {
  color: #1890ff;
  font-size: 19px;
}
.ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component{
  z-index: 1;
}
.ant-badge .ant-scroll-number{
  font-size: 8px;
  z-index: 1;
}
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-content
//   > table
//   > thead
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-header
//   > table
//   > thead
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-body
//   > table
//   > thead
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-summary
//   > table
//   > thead
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-content
//   > table
//   > tbody
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-header
//   > table
//   > tbody
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-body
//   > table
//   > tbody
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-summary
//   > table
//   > tbody
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-content
//   > table
//   > tfoot
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-header
//   > table
//   > tfoot
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-body
//   > table
//   > tfoot
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-summary
//   > table
//   > tfoot
//   > tr
//   > th,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-content
//   > table
//   > tfoot
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-header
//   > table
//   > tfoot
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-body
//   > table
//   > tfoot
//   > tr
//   > td,
// .ant-table.ant-table-bordered
//   > .ant-table-container
//   > .ant-table-summary
//   > table
//   > tfoot
//   > tr
//   > td {
//   border-right: 1px solid #eae3e3;
// }
// .ant-table-tbody > tr > td {
//   border-bottom: 1px solid #eae3e3 !important;
// }

.checkbox-input {
  z-index: 1;
}


.red-row{
  background-color: rgb(238, 153, 153) !important;
}

.green-row{
  background-color: rgb(164, 221, 164) !important;
}

.blue-row{
  background-color: rgb(99, 209, 200) !important;
}

.ant-table-wrapper td.ant-table-column-sort {
   background: none !important
}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td, 
:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table-tbody >tr >td.ant-table-cell-row-hover{
  background: #fafafa !important;
}

.ant-table-thead th{
  white-space: nowrap !important;
}

 table > tbody > tr.ant-table-row.ant-table-row-level-0.totalRow-success > td.ant-table-cell{
   color: green !important;
	font-weight: 900 !important;
  font-size:12px !important;
  background-color: rgb(194, 188, 188);
}

