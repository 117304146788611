/* .disclaimer p{
    word-spacing:normal;
  } */
  .text-red{
    color: red;
  }
  .k-pdf-export p , .k-pdf-export h4, .k-pdf-export h5{
    word-break: keep-all !important;
  }
  /* .k-pdf-export p{
    font-size: 8.8px;
    padding: 0 4px;
    margin: 0;
    word-break: break-all;
  } */
  .k-pdf-export header h3{
    width: 33.3%;
  }
  .k-pdf-export h3{
    font-size: 12px;
    /* font-weight: bold; */
    margin-bottom: 0;
  }
  .k-pdf-export h4{
    font-size: 8.8px;
  }
  .k-pdf-export h5{
    font-size: 8.8px;
    margin-bottom: 0;

  }
  
  .k-pdf-export h6{
    font-size: 8.8px;
    margin-bottom: 0;
  }