@import "../../Style/Variable/Variable.scss";

.app-spinner {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
