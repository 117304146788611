@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.ant-switch-checked {
  padding: 4px;
}

.app-swicth label {
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

.text-hover {
  position: absolute;
  width: 200px;
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
  z-index: 4545454;
  color: white;
  background-color: rgba(78, 78, 78, 0.64);
  display: none;
}

.hover-text:hover .text-hover {
  display: block;
}/*# sourceMappingURL=AppSwitch.css.map */