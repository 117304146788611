.input-file {
    display: flex;
 justify-content: center;
 width: 100%;
 position: relative;
 
 }
 .input-file input {
    opacity: 0;
    height: 100% !important;
    width: 100%;
    position:absolute;
    z-index: 2;
    top: 0;
    padding: 1px;
    margin-top: 0;
 }
 .input-file .icons {
     height: 100%;
     width: 100%;
     padding: 2px;
     position:absolute;
     top: 0;
     z-index: 1;
 }
 .input-file button{
     height: 100%;
     width: 100%;
margin: 0;
 }
 
 .upload .delete{
     cursor: pointer;
 }
 
 .upload .filelist:hover{
     background-color: #FCF9BE;
 }
 .upload {
     font-size: 12px;
 }
 .upload button{
     font-size: 16px;
     padding-bottom: 5px;
 }