/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
:root{
  --antd-font-family:"Source Sans Pro", sans-serif !important;
}
.ant {
  font-family: var(--antd-font-family) !important;
}
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

body{
  font-family: "Source Sans Pro", sans-serif !important;
}
.b-text-style {
  
  color: #777777;
  font-weight: 500;
}

.bg-light-yello-200 {
  background: #fffffa;
}

.bg-light-blue-300 {
  background: #eff8ff;
}

.bg-light-blue-900 {
  background: #307BF6;
}

.text-orange {
  color: #F24C3D !important;
}

.ant-form-item-label > label {
  
  color: #777777;
  font-weight: 500;
}

.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td {
  padding: 3px;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  color: #fff;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  padding: 5px;
}

.ant-table-wrapper .ant-table-column-sorter {
  display: none;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: #7cffa7;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 10px;
  
  margin-top: 2px;
}

.ant-input[disabled] {
  color: #777777;
}

.ant-layout-content .site-layout-background {
  margin: 0 !important;
}

.ant-spin-container {
  padding: 0 !important;
}

.ant-layout .site-layout {
  padding: 15px;
}

.ant-input:-moz-placeholder-shown {
  
  color: #777777;
  font-weight: 400;
}

.ant-input:placeholder-shown,
.ant-input {
  
  color: #777777;
  font-weight: 400;
}

.b-text-sapn {
  
  font-size: 14px;
  color: #777777;
  font-weight: 600;
}

.btn:focus {
  box-shadow: none !important;
}

.mytab .ant-tabs-nav-wrap {
  background-color: #FFFFFF;
  margin: 0 !important;
  padding: 0 !important;
}

.mytab .ant-tabs-tab {
  padding: 10px !important;
}

.mytab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFFFF;
}

.mytab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: #092F50;
  margin-bottom: 0;
  border-bottom: none !important;
}

.tax-card-tab .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #CACACA !important;
  height: 38px !important;
}

textarea:focus,
textarea:active {
  border-color: #40a9ff !important;
  /* box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); */
}

.myexcel .ant-upload {
  box-sizing: border-box;
  text-align: center !important;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  outline: 0;
  width: 100% !important;
}

.file .ant-upload {
  box-sizing: border-box;
  text-align: center !important;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  outline: 0;
}

.myfile .ant-upload.ant-upload-drag {
  position: relative;
  width: 100% !important;
  height: 100px !important;
  text-align: center !important;
  background: white !important;
  border: 3px dashed #eeeeee !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 11px !important;
  opacity: 1;
  cursor: pointer;
  transition: border-color 0.3s;
}

.myfile .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 2px !important;
}

.tax-card .ant-card-head {
  min-height: 40px !important;
  height: 50px !important;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #FFFFFF !important;
  font-weight: 500;
  font-size: 16px;
  background: #092F50 !important;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.tax-cad-icon {
  color: #307bf6;
  border: 1px solid #307bf6;
  background: #FFFFFF !important;
  width: 20px !important;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.tax-card-table .ant-table-thead > tr > th {
  background: #EFF8FF !important;
  color: #4F4F4F;
}

.assessed-table .ant-table-thead > tr > th {
  background: #CACACA !important;
  color: #4F4F4F;
}

.border-color {
  border: 2px solid #CACACA !important;
}

.heading-1 {
  
  font-size: 20px;
  color: #4F4F4F;
  font-weight: 900;
}

.heading-4 {
  
  font-size: 13px;
  color: #777777;
  font-weight: 600;
}

.heading-5 {
  
  font-size: 12px;
  color: #777777 !important;
  font-weight: 600;
}

.description {
  
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.fs-10 {
  
  font-size: 10px;
  color: red !important;
  font-weight: 500;
}

.fs-12 {
  
  font-size: 12px;
  color: red !important;
  font-weight: 500;
}

.fs-11 {
  
  font-size: 11px;
  color: red !important;
  font-weight: 500;
}

.fs-18 {
  
  font-size: 18px;
  color: #4F4F4F !important;
  font-weight: 500;
}

.fs-8 {
  font-size: 8px;
}

.fs-13 {
  font-size: 13px !important;
  margin: 0;
}

.error-span {
  
  font-size: 10px;
  color: red !important;
  font-weight: 500;
}

main {
  height: 88vh;
  overflow: auto;
}

.main-bg-color {
  background: #222b54;
}

.secondary-bg-color {
  background: #f7f9fc;
}

.k-tabstrip {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.success-bg-color {
  background: rgb(81, 231, 81);
}

.danger-bg-color {
  background: rgb(255, 0, 0);
}

.success-text-color {
  color: rgb(81, 231, 81);
}

.danger-text-color {
  color: rgb(255, 0, 0);
}

.main-text-color {
  color: #222b54;
}

.plus-icon {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-empty-normal {
  margin: 0 !important;
}

.ant-empty-normal .ant-empty-image {
  height: 20px !important;
}

.ant-empty-description {
  font-size: 10px;
}

:where(.css-dev-only-do-not-override-k83k30).ant-badge .ant-badge-multiple-words {
  padding: 0 !important;
}

.gray-table-col .ant-table-thead > tr > th {
  
  font-size: 11px;
  color: #000000;
  font-weight: 600;
  background: rgba(221, 229, 231, 0.3607843137) !important;
  padding: 3px 5px;
}

.gray-table-col .ant-table-tbody > tr > td {
  padding: 5px;
  
  font-size: 10px;
  color: #5f5f5f;
  font-weight: 600 !important;
}

.gray-table-col .ant-input {
  font-size: 10px !important;
  line-height: 0 !important;
}

.gray-table-col .ant-table-tbody .btn-sm {
  padding: 2px 4px !important;
  font-size: 10px !important;
}

.gray-table-col.conflicts .ant-table-column-title {
  font-size: 11px;
  color: #000000;
}

.gray-table-col.conflicts .ant-table-filter-trigger.active {
  font-size: 13px;
}

.ant-modal-body {
  padding-bottom: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-popover-inner-content {
  width: 300px !important;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-column-title {
  color: #494949 !important;
}

.search-filter-form .ant-form-item {
  margin-bottom: 0 !important;
}

.search-filter-form .ant-form-item {
  margin-bottom: 0 !important;
}

.gray-table .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.gray-table.ant-collapse {
  border: 1px solid #d9d9d9;
}

.gray-table .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 6px 3px;
}

.fs-9 {
  font-size: 9px !important;
}

.hover-text-content {
  position: absolute;
  z-index: 2;
  left: 0;
  display: none;
  max-height: 200px;
  width: 250px;
  overflow: auto;
  background: rgb(244, 234, 234);
}

.click-text-content {
  position: absolute;
  z-index: 2;
  top: 25px;
  left: 0;
  display: none;
  width: 300px;
  background: rgb(244, 234, 234);
}

.import-click-text-content {
  position: absolute;
  z-index: 2;
  top: 25px;
  right: 0;
  display: none;
  width: 300px;
  background: rgb(244, 234, 234);
}

.hover-text-content > p {
  margin: 0 !important;
  font-size: 10px;
  color: #000;
}

.hover-text-comp {
  position: relative;
}

.hover-text-comp:hover .hover-text-content {
  display: block;
}

.click-content-comp.active .click-text-content {
  display: block;
}

.click-content-comp.active .import-click-text-content {
  display: block;
}

.border-dashed {
  border: 1px dashed rgba(0, 0, 0, 0.85);
}

.border-dotted-2 {
  border: 2px dotted rgba(0, 0, 0, 0.85);
}

.dim-blue-bg-color {
  background: #f0fff9 !important;
}

.comment-bg-color {
  background: #fafeff !important;
}

.dark-label {
  
  font-size: 12px;
  color: #092F50 !important;
  font-weight: 700;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.tooltip-inner {
  background-color: red !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff !important;
  background: #627b91 !important;
  border-color: rgba(255, 255, 255, 0.9490196078) !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):disabled {
  border: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: none !important;
}

.page-break {
  page-break-before: always;
}

.pdf-view {
  height: 0px;
  overflow: hidden;
  word-break: break-all;
  text-align: justify;
  text-justify: inter-word;
  word-wrap: break-word;
}

.ant-layout .ant-layout-sider {
  background-color: #092f50;
}

.comments-collase {
  background-color: rgba(103, 129, 161, 0.7019607843);
  padding-bottom: 0.8px;
}

.comments-collase .ant-collapse-header .ant-collapse-header-text {
  font-weight: bold;
}

.comments-collase .ant-collapse-content-box {
  padding: 0px !important;
}

.cancel-border {
  border: 1px solid #9ea2ac !important;
}

.editable-cell-value-wrap {
  padding-right: 0 !important;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.ant-table-content colgroup {
  background: white;
}

/* width */
.animate-pop {
  color: red;
  font-weight: bold;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes example {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}/*# sourceMappingURL=common.css.map */

/* Tabs styling */
.ant-tabs-nav-list .ant-tabs-tab{
  /* transition: background-color 0.2s, color 0.2s; */
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  font-weight: 500;
  color: #03070e;
  text-decoration: none;
  width: 100%;
  font-size: 14px;
}
.ant-tabs-nav-list .ant-tabs-tab:hover {
  background-color: #0d86ff; 
  color: #fff !important;
}

.ant-tabs-nav-list .ant-tabs-tab-active {
  background-color: #222b54; 
  color: #fff !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
  outline: none !important;
  background-color: none; 
  border-bottom: 0;
}
.ant-tabs .ant-tabs-tab-btn{
  outline: none !important;
  transition: none;
}
.ant-tabs-ink-bar {
  display: none !important;
}
.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}
.ant-tabs-tab{
  margin-left: .5rem !important;
}
.settings-container, canvas{
  font-family: "Source Sans Pro", sans-serif !important;
}
.ant-row, .ant-col, .ant-card, .ant-table-wrapper .ant-table,
.ant-select-single .ant-select-selector, .ant-layout, .ant-tabs, .ant-btn,
.ant-input, .ant-dropdown-menu, .ant-modal, .ant-tree, .ant-select, .ant-select-dropdown,
.ant-collapse, .ant-pagination .ant-pagination-item{
  font-family: "Source Sans Pro", sans-serif !important;
}