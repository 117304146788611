@import "../../Style/Variable/Variable.scss";


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.ant-layout-sider{
  height: 92vh;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
}

// .site-layout .site-layout-background {
//   background: $color-white;
// }


.site-layout .site-layout-background{
  background:none !important;
}
.ant-layout-sider, .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  background-color: $color-primary ;
}

.ant-layout-footer, .ant-layout .site-layout{
  padding: 0;
}
.outlet{
  padding: 15px;
}


.ant-menu-title-content a{
  @include font($font-family, "font_14", normal, $color-white )
}

.sidebar-icon{
  position: absolute;
  right: -8px;
  top: -11px;
  z-index: 10;
  margin-bottom: 10px;
  cursor: pointer;
}
.ant-menu-title-content a{
  text-decoration: none !important;
}

.sidebar-icon .trigger{
  background-color: $color-primary;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu-title-content a{
  text-decoration: none !important;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
  min-width: 50px !important;
  max-width: 50px !important;
  width: 50px !important;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed li{
  padding-left: 13px !important;
}
