@import "../../Style/Variable/Variable.scss";

.login{
  background-size: cover;
  display: flex;
  flex-direction: column ;
  justify-content: space-between;
  align-items: center;
}
.logoImage {
	
	width: 60%;
	margin-bottom: 50px;
}

.login-input-field input{
  width: 100%;
}
input:focus{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}
input::placeholder{
    @include font($font-family, "font_12", medium, $color-gray);
}

.forgot-password {
    @include font($font-family, "font_12", medium, $color-blue);
    text-decoration: none;
}


