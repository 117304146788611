@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.title-bottom {
  margin-bottom: 20px !important;
}

.ant-drawer .ant-drawer-title {
  color: blue !important;
  padding: 0 !important;
  margin: 0 !important;
}

.invoice-chart-row {
  border-top: 5px solid rgb(34, 34, 83) !important;
  border-radius: 10px !important;
  padding: 10px !important;
  height: 450px !important;
}

.close-bixbtn {
  color: "#cacaca";
}

.search-btn.disabled {
  cursor: not-allowed;
}

.invoice-tab {
  margin-bottom: 3px;
}

.order .ant-drawer-body {
  padding: 0 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.total-count-cards-report {
  background-color: #EFF8FF !important;
  font-weight: bold;
  font-size: 14px;
  color: #061238;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px !important;
  border: 1px solid gray !important;
}

.total-count-row .ant-card .ant-card-body {
  padding: 0 !important;
}

.auto-total-count-cards-report {
  background-color: rgb(194, 188, 188) !important;
  font-weight: bold;
  font-size: 14px;
  color: #061238;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px !important;
  border: 1px solid gray !important;
}

.auto-total-count-row .ant-card .ant-card-body {
  padding: 0 !important;
}

.paylog-row {
  background-color: rgb(240, 187, 166);
  font-weight: bold !important;
  color: black !important;
}

table > tbody > tr.ant-table-row.ant-table-row-level-0.paylog-row > td.ant-table-cell.ant-table-cell-row-hover {
  background-color: rgb(240, 187, 166) !important;
}

.log-message-pre {
  font-family: "Montserrat", sans-serif !important;
  font-size: 11px !important;
  color: rgb(202, 49, 49) !important;
  font-weight: 600 !important;
}

table > tbody > tr.ant-table-row.ant-table-row-level-0.totalRow-unassinged-count > td.ant-table-cell.ant-table-cell-row-hover {
  background-color: rgb(240, 187, 166) !important;
}

table > tbody > tr.ant-table-row.ant-table-row-level-0.totalRow-unassinged-count > td.ant-table-cell {
  background-color: rgb(240, 187, 166) !important;
}

.logTable {
  border: 1px solid #dddddd;
  border-collapse: collapse;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.logTable th {
  border: 1px solid #dddddd;
  text-align: center !important;
  padding: 4px !important;
  font-weight: bold;
  background-color: #e7e9f0;
}

.logTable td {
  border: 1px solid #dddddd;
  text-align: center !important;
  padding: 4px !important;
}/*# sourceMappingURL=Reports.css.map */