@import "../../Style/Variable/Variable.scss";

.ant-dropdown-menu-item:hover{
    background: $color-blue;
    color: $color-white;
  }
  .ant-dropdown-menu-item {
    @include font($font-family, "font_12", normal, $color-gray);
    transition: 0.25s all ease-in-out;
  }
header{
  background: $color-white !important;

}
  .avtar{
    width: 29px;
  height: 29px; 
  }
  .avtar-name{
    @include font($font-family, "font_14", medium, $color-gray);
    text-decoration: none;
  }
  
  .ant-dropdown-menu-title-content a{
      text-decoration:  none;
  }