@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.my-account-heading {
  border-bottom: none;
  padding-bottom: 20px;
  color: #4F4F4F;
}

.reset_password label {
  
  font-size: 13px;
  color: #CACACA;
  font-weight: 600;
}

.reset_password input::-moz-placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

.reset_password input, .reset_password input::placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

.my-account input:checked {
  border: none !important;
  box-shadow: none !important;
}

.update_header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.heading {
  display: none;
}

.reset_password {
  padding-top: 100px;
}

@media (max-width: 768px) {
  .label-hide {
    display: none;
  }
}
.login-back {
  
  font-size: 10px;
  color: #307BF6;
  font-weight: 600;
}/*# sourceMappingURL=ResetPassword.css.map */