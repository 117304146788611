@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.top-line {
  height: 2px solid #CACACA;
}

hr:not([size]) {
  height: 2px;
}

.private-lable {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137) !important;
}

.border-style {
  border-start-start-radius: 8px !important;
}

.border-endstyle {
  border-start-end-radius: 8px !important;
}/*# sourceMappingURL=customer.css.map */

/* .ant-radio-wrapper.ant-radio-checked {
  color: #1890ff; 
  font-weight: 500;
} */