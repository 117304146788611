.manual-entry {
  box-shadow: #fff;
}

.order-hr {
  height: 1px !important;
}

.manual-entry .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}/*# sourceMappingURL=ManualEntry.css.map */