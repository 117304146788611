@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.tax-card-table.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px;
  
  font-size: 11px;
  color: #5f5f5f;
  font-weight: 600;
  background-color: #f7f7f7 !important;
}

.tax-card-table .gray-table-col {
  overflow-x: auto !important;
}

.tax-card-table .gray-table-col::-webkit-scrollbar {
  height: 7px !important;
  border-radius: 8px !important;
}

.tax-card-table .gray-table-col::-webkit-scrollbar-thumb {
  background: #B4B4B3 !important;
  border-radius: 8px;
  margin-left: 15px;
}

.tax-card-table .gray-table-col::-webkit-scrollbar-track {
  background: #c2d7ff !important;
}

.juri-table tbody td {
  height: 22px !important;
}

.table > :not(caption) > * > * {
  padding: 0.1rem 0.1rem;
}

.tax-card-icon {
  color: #307bf6;
  border: 1px solid #307bf6;
  background-color: white !important;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.juri-table .app-table .ant-table-wrapper {
  box-shadow: none !important;
}

.juri-table .ant-table-thead > tr > th {
  border: 1px solid #f7f7f7 !important;
  box-shadow: 0 4px 2px -2px gray !important;
}

.exemption .ant-table-thead > tr > th {
  background: rgba(64, 250, 255, 0.1294117647) !important;
  color: #4f4f4f;
  padding: 3px 3px !important;
  font-size: 10px;
}

.exemption tr > td {
  background: #fff !important;
  color: #4f4f4f;
  padding: 3px 3px !important;
  font-size: 9px;
}/*# sourceMappingURL=MultiJurisdiction.css.map */