.title-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-tabs-nav-wrap {
  background-color: #EFF8FF;
  width: 100%;
  border-radius: 2px;
  padding: 0.2rem 0.7rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137) !important;
  font-size: 13px;
  color: #777777;
  font-weight: 600;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px;
}

.ant-tabs-tab-btn {
  font-size: 12px;
}/*# sourceMappingURL=settings.css.map */