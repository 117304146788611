@import "../../../Style/Variable/Variable.scss";

.tax-card-icon{
    color: #307BF6;
     border: 1px solid $color-shadow !important;
    background-color: white !important;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.tax-card-tab .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: $color-lightgray !important;
  height: 38px !important;
}

.tax-card-tab  .ant-tabs-nav-wrap {
    background-color: #EFF8FF;
    width: 100%;
    border-radius: 2px;
    padding: 0.2rem 0.7rem;
    box-shadow: 0px 0px 0px #00000029 !important;
    
    font-size: 13px;
    color: #777777;
    font-weight: 600;
}
.tax-card-tab .ant-collapse-content .ant-collapse-content-box {
    padding-bottom: 0px !important;
    padding-top: 5px !important;
    margin-top: -5px !important;
    padding: 0px;
}
 
//  .tax-card-table .ant-table-thead > tr > th {
//   background: $color-light-blue !important;
//   color: $colo-black1;
// }
//   .tax-card-table .ant-table-thead > tr > th {
// 	background: $color-dim-blue !important;
// 	color: $colo-black1;
//     padding: 3px 3px !important;
// }
  .tax-card-table .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 1px;
    
    font-size: 11px;
    color: #5F5F5F;
    font-weight: 600;
    background-color:#f7f7f7 !important;
}
    .tax-card-tab .ant-tabs-tab-btn {
    font-size: 14px !important;
}
.tax-card-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background:none !important;
    border: none !important;
    /* background: #fafafa; */
    /* border: 1px solid #f0f0f0; */
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}