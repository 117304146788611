@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.input-erro {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
  margin-bottom: 10px;
}

textarea:invalid[focused=true] {
  border-bottom: 1px solid red !important;
  background-color: #fff2f4 !important;
}

textarea:focus,
textarea:active,
textarea[focused=true] {
  display: block !important;
  border-color: #40a9ff;
  outline: 0;
}

textarea {
  padding: 3px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #777777 !important;
}

textarea:focus:invalid {
  border: 2px solid #f5192f !important;
  box-shadow: 0 0 4px #f5192f !important;
  color: red !important;
}

textarea:focus:invalid ~ .input-erro {
  display: block;
}

textarea::-moz-placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

textarea::placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}/*# sourceMappingURL=appText.css.map */