@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.excelupload {
  position: relative;
  display: flex;
  text-align: center !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  background: white !important;
  padding: 4px 11px;
  line-height: 1.6715;
  display: inline-flex;
  border: 2px solid #E1E1E1 !important;
  opacity: 1;
  cursor: pointer;
  transition: border-color 0.3s;
}

.test {
  word-break: break-all !important;
}/*# sourceMappingURL=AppExcelUpload.css.map */