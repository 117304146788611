
  
  h2 {
    margin-top: 40px;
  }
  
  .rc-color-picker-panel {
    border: 1px solid #ccc;
  }
  .rc-color-picker-panel-inner {
    border: none;
    box-shadow: none;
  }
  .rc-color-picker-panel-board-hsv {
    border-radius: 12px;
    outline: none;
  }
  .rc-color-picker-panel-board-value {
    border: none;
    border-radius: 12px;
  }
  .rc-color-picker-panel-board-saturation {
    border: none;
    border-radius: 12px;
  }
  .rc-color-picker-panel-ribbon {
    border-radius: 12px;
  }
  .rc-color-picker-panel-wrap-preview {
    border-radius: 12px;
  }
  .rc-color-picker-panel-preview span {
    border-radius: 12px;
  }
  .rc-color-picker-panel-preview input {
    border-radius: 12px;
  }
  
  .input-color-box {
    width: 60px;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
  }
    
  .color-box{
    position: absolute;

  }
  .rc-color-picker-wrap{
    width: 100%;
  }
  .rc-color-picker-trigger{
    position: absolute;
    width: 30px !important;
    z-index: 1;
    left: 27% !important;
    top: 30% !important;
    height: 30px !important;
    background:none !important;
    border: none !important;
  }
  