@import "../../Style/Variable/Variable.scss";

.ant-switch-checked {
   padding: 4px;
}
.app-swicth label{
   @include font($font-family, "font_12", medium,$color-gray);
}
.text-hover{
   position: absolute;
   width: 200px;
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
  z-index: 4545454;
  color: white;
  background-color: rgba(78, 78, 78, 0.64);
  display: none;
}

.hover-text:hover .text-hover{
   display: block;
}