
.notes-btn{
    background-color: #0d86ff;
    color: #ffffff;
    font-size: small;
}
.w-10{
    width: 10%;
}
.notes-cancel{
    background-color: #CACACA;
}
.notes-bg{
    background-color: #EFF8FF;
}
.notes-height{
    height: 68vh;
    overflow-y: scroll;
    font-size: 12px;
}
.font-bold{
    font-weight: 500;
}
.rotate-90 {
    transform: rotate(90deg);
  }
.reply-btn{
        color: #0d86ff;
        border: 1px solid #0d86ff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10%;
        cursor: pointer;
}