  
$color-red:red;
$colo-black1:#4F4F4F;
$colo-black:#000000;
$color-gray:#777777;
$color-lightgray:#CACACA;
$color-white:#FFFFFF;
$color-gray2:#E1E1E1;
$color-gray3:#5F5F5F;
$color-gray4:#bdb6b6;
$color-blue:#307BF6;
$color-primary:#222b54;
$color-light-blue:#EFF8FF;
$color-success:#7CFFA7;
$color-success2:#198B65;
$color-coral:#FF9A9A;
$colo-mustard:#FFF08D;
$color-body:#F0F0F0;
$color-shadow:#00000029;
$color-dark-blue:#092F50;
$color-dim-gray:#666768;
$color-dim-blue:#f0fff9;
$color-blue3:#fafeff;



//fonts
$font-Size:(
    "font_10":10px,
    "font_11":11px,
    "font_12":12px,
    "font_13":13px,
    "font_14":14px,
    "font_15":15px,
    "font_16":16px,
    "font_17":17px,
    "font_18":18px,
    "font_19":19px,
    "font_20":20px,
    "font_22":22px,
);


$fontweight:(
    "normal":500,
    "medium":600,
    "medium2":700,
    "bold":900,
    "light":400
);
$font-family:"Montserrat";

// $fontsize:(
//     "font_xl"
//     "font_l"
//     "font_med"
//     "font_sm"
//     "font_xs"
// );

// $buttonprimarylarge:{
//     max-width
//     min width
//     $color
//     background-color
//     padding\
//     margin
// }



// $buttonsecondary:{
//     max-width
//     min width
//     $color
//     background-color
// }

// padding_label
// margin_field_top
// margin_field_bottom:
// padding_field_top
// padding_field_bottom

@mixin font($style,$size, $weight, $color) {
    font-family: $style,sans-serif;
    font-size: map-get($font-Size, $size );
    color: $color ;
    font-weight: map-get($fontweight, $weight );
}

//button
@mixin b-button($bg-color, $padding, $radius) {
    background-color: $bg-color;
    padding: $padding ;
    border:1px solid $bg-color  ;
    border-radius: $radius ;
}