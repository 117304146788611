@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.vendor-isactive {
  background-color: #7CFFA7;
}

span.ant-switch-inner {
  color: #4F4F4F !important;
}

.my-switch.ant-switch.ant-switch-checked {
  display: flex;
  align-items: center;
}

.my-switch.ant-switch {
  background: #FF9A9A;
  background-image: none;
}

.my-switch.ant-switch-checked {
  background: #7CFFA7;
  background-image: none;
}

.add_icon {
  color: #307BF6;
  border: 1px solid #307BF6;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.vendor .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .vendor .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  border: 2px solid green;
}

.vendor .fileContainer {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.my-switch.ant-switch {
  min-width: 100px;
}/*# sourceMappingURL=vendor.css.map */