@media (max-width: 768px) {
  .b-btn1 {
    padding: 5px 20px;
  }
  .username {
    display: none;
  }
  .ant-dropdown-menu {
    width: 150px;
  }
}/*# sourceMappingURL=Responsive.css.map */