@import "../../Style/Variable/Variable.scss";

.treeSelect{
    overflow: auto;
}

/* width */
.treeSelect::-webkit-scrollbar {
    width: 5px;

  }
  
  /* Track */
  .treeSelect::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   .treeSelect{
  overflow-x: hidden;
   }
  /* Handle */
  .treeSelect::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .treeSelect::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
.treeselect-input:focus{
    border: none !important;
    color: $color-gray !important;
}
.treeselect-input{
    border: none !important;
    height: 100%;
    width: 87% !important;
    margin: auto ;
}
.treeselect-input-box{
border: 1px solid lightgray !important;
}

.search-icon-tree{
    position: absolute;
    bottom: 20%;
   
}
.dark-theme .search-icon-tree svg, .dark-theme  .treeselect-input-box input::placeholder{
    color: lightgray !important;
}
.ant-tree-node-content-wrapper-open, .ant-tree-node-content-wrapper-close{
    @include font($font-family, "font_11", medium2, $color-gray !important )
}
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal .ant-tree-title{
    @include font($font-family, "font_11", light, $color-gray !important )
}