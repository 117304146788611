@import "../../../../Style/Variable/Variable.scss";
    .top-line{
   height: 2px solid $color-lightgray;
    }
    hr:not([size]) {
    height: 2px;
}   
.private-lable{
    box-shadow: 0px 3px 6px $color-shadow !important;
}

.border-style{
    border-start-start-radius: 8px !important;
}
.border-endstyle{
    border-start-end-radius: 8px !important;
}