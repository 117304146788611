@import "../../Style/Variable/Variable.scss";

.resend-btn {
  border: none;
  @include b-button("none", 0, 7px);
  @include font($font-family, "font_13", medium, $color-primary);
}
.login-back {
  @include font($font-family, "font_10", medium, $color-light-blue);
}
