.notice-main-info{
    list-style: none;
    font-size: 10px;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0 !important;
}
.notice-certificate span{
    font-size: 10px;
}
.notice-certificate h2{
    font-size: 15px !important;
}
.notice-certificate{
    font-family: serif;
}
/* .notice-certificate .dynamic-content .lines span:last-child{
    line-height: 0;
    margin-top: -1px;
    display: inline-block;
} */
.notice-logo-info span{
    font-size: 12px !important;
}
.notice-second-page h3{
    font-size: 10px;
    word-spacing: 2px;
    line-height: 18px;
}