@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-title {
  
  font-size: 20px;
  color: #777777;
  font-weight: 500;
}

.ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ant-modal-body label {
  
  font-size: 13px;
  color: #777777;
  font-weight: 700;
}

.modal-close {
  right: 20px;
  top: 15px;
}

.modal-close .confirm-box {
  width: 350px;
  background: rgb(255, 255, 255);
  right: 0;
  z-index: 10000;
}

.ant-modal-close {
  display: none !important;
}/*# sourceMappingURL=AppModal.css.map */