@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.my-account-heading {
  border-bottom: 2px solid #CACACA !important;
  padding-bottom: 20px;
  color: #4F4F4F;
}

.ant-result {
  padding: 50PX 20px 20px;
}

.my-account label {
  
  font-size: 12px;
  color: #4F4F4F;
  font-weight: 600;
}

@media (max-width: 768px) {
  .label-hide {
    display: none;
  }
}
.ant-result-success .ant-result-icon > .anticon {
  color: #198B65;
}

.ant-result-icon {
  margin-bottom: 0;
}

.ant-result-title {
  
  font-size: 16px;
  color: #198B65;
  font-weight: 600;
}/*# sourceMappingURL=MyAccount.css.map */