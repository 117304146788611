@import "../../../Style/Variable/Variable.scss";
.jurisdiction-isactive {
  background-color: $color-success;
  // @include font($font-family, "font_10", medium,$color-success);
}
span.ant-switch-inner {
  color: $colo-black1 !important;
}
.my-switch.ant-switch.ant-switch-checked {
  display: flex;
  align-items: center;
}
.my-switch.ant-switch {
  background: $color-coral;

  background-image: none;
}
.my-switch.ant-switch-checked {
  background: $color-success;
  background-image: none;
}
.annotation-button .add_icon {
  color: $color-blue;
  border: 1px solid $color-blue;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 5px;
}

.jurisdiction .fileContainer {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.my-switch.ant-switch {
  min-width: 30px;
}
.annotation-view {
  min-height: 100px;
  padding: 5px;
}
.annotation-view p {
  @include font($font-family, "font_12", medium, $color-gray);
}

.editor .rsw-ce {
  height: 200px;
}
