@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.jurisdiction-isactive {
  background-color: #7CFFA7;
}

span.ant-switch-inner {
  color: #4F4F4F !important;
}

.my-switch.ant-switch.ant-switch-checked {
  display: flex;
  align-items: center;
}

.my-switch.ant-switch {
  background: #FF9A9A;
  background-image: none;
}

.my-switch.ant-switch-checked {
  background: #7CFFA7;
  background-image: none;
}

.annotation-button .add_icon {
  color: #307BF6;
  border: 1px solid #307BF6;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 5px;
}

.jurisdiction .fileContainer {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.my-switch.ant-switch {
  min-width: 30px;
}

.annotation-view {
  min-height: 100px;
  padding: 5px;
}

.annotation-view p {
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

.editor .rsw-ce {
  height: 200px;
}/*# sourceMappingURL=Jurisdiction.css.map */