.k-animation-container{
    width: 100%;
}
.k-tabstrip-items-wrapper{
    background: #eff8ff;
    padding: 5px 0px;
}
.k-tabstrip-items-wrapper .k-item{
    color: black;
}
.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-items-wrapper .k-item.k-active, .k-tabstrip-items-wrapper .k-item.k-selected{
    color:#0d6efd;
    background: #eff8ff;
    border: none;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2px;
}

#root + div + .ant-select-dropdown {
    display: none;
  }


  .custom-dropdown {
    position: absolute; /* Ensure dropdown is positioned correctly */
    z-index: 1000; /* Adjust z-index if necessary */
    overflow: auto; /* Allow scrolling if content overflows */
}

.app-select-hoa {
    width: 100% !important;
    
}

