@import "../../Style/Variable/Variable.scss";
.excel-upload {
    position: relative;
    display: flex;
    text-align: center !important;
    width: 100% !important;
     height:100% !important;
    text-align: center !important;
    background:$color-light-blue !important;
    padding: 4px 11px;
    line-height: 1.6715;
    display: inline-flex;
    // border: none !important; 
    //  border: 3px dashed #eeeeee !important;
    // box-shadow: 0px 3px 6px #00000029 !important;
    border: 2px solid #E1E1E1 !important;
    opacity: 1;
    cursor: pointer;
    transition: border-color 0.3s;
}
    
    