@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.formInput {
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-top: 20px;
  float: left;
  /* margin-right: 40px; */
}

input {
  padding: 13px 6px;
  margin-top: 20px;
  border: none;
  border-bottom: 2px solid lightgray;
  font-weight: 500;
  border-radius: 2em;
  font-family: inherit;
}

.custome-file {
  opacity: 0;
  background: red;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.icon-input {
  padding-right: 30px !important;
}

.file-button {
  margin-top: 10px;
  display: inline-block;
  background: rgb(247, 248, 249);
  padding: 3px 15px;
  border: 1px solid #4F4F4F;
  font-weight: 600;
}

.user-modal .input-icon {
  top: 40px;
  padding-left: 10px;
  right: 0;
}

input[type=file]::-moz-placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

input[type=file]::placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

.input-error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
  margin-bottom: 10px;
}

input[name=password] {
  padding-right: 22px !important;
}

input:active {
  border-bottom: 1px solid blue !important;
}

input:invalid[focused=true] {
  border-bottom: 1px solid red;
  background-color: #fff2f4;
}

input:invalid[focused=true] ~ span {
  display: block;
}

input:invalid[focused=true] ~ .input-icon {
  color: red;
}

/* input:not([type="button"]):not([type="submit"]):not([type="reset"]):hover, input:not([type="button"]):not([type="submit"]):not([type="reset"]):focus, textarea:hover, textarea:focus, select:hover, select:focus {
    border-color: #81256f;
    box-shadow: none;
} */
input:focus,
input:active,
input[focused=true] {
  border-bottom-color: #40a9ff;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, .2); */
  outline: 0;
}

.alertIcon {
  padding-right: 0.17647rem;
  top: 0.17647rem;
}

.disable-input input:focus,
.disable-input input:active,
.disable-input input[focused=true] {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 2px solid #CACACA !important;
}

.disable-input input {
  cursor: not-allowed;
}

.input-field input {
  margin-bottom: 10px;
  padding-bottom: 10px !important;
  font-family: inherit;
  font-size: 12px !important;
  line-height: inherit;
}

.input-field {
  background: none;
}

/* .input-icon {
  position: absolute;
  right: 32px;
  top: 1px;
  font-size: 15px;
} */

.icon-dark {
  color: #4F4F4F;
}

.passIcon {
  cursor: pointer;
}

input::-moz-placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}

input::placeholder {
  
  font-size: 12px;
  color: #CACACA !important;
  font-weight: 600;
}