@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.orderheader {
  
  font-size: 13px;
  color: #307BF6;
  font-weight: 600;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  color: #E1E1E1;
  background-color: #E1E1E1;
  box-shadow: inset 0 -1px 0;
  opacity: 1;
  border-bottom: none !important;
  border-top-left-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.accordion-item {
  border: none;
}

.accordion-item:first-of-type .accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.ant-table-filter-trigger {
  left: 0% !important;
  color: #7CFFA7;
  width: 30px;
  z-index: 1;
  position: relative;
}

/* width */
.ant-table-content ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.ant-table-content ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-content ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ant-table-content ::-webkit-scrollbar-thumb:hover {
  background: #555;
}/*# sourceMappingURL=orderwizard.css.map */