.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.show-check .ant-checkbox-checked::after{
  border:  none !important;
}
.show-check .ant-checkbox-inner{
  display: none !important;
}
