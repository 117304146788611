@import "../../../../Style/Variable/Variable.scss";
.vendor-isactive {
    background-color:$color-success;
	// @include font($font-family, "font_10", medium,$color-success);
}
span.ant-switch-inner{
    color:$colo-black1 !important;
}
.my-switch.ant-switch.ant-switch-checked{
    display: flex;
    align-items: center;
}
.my-switch.ant-switch{
    background: $color-coral ;
 
    background-image: none ;
}
.my-switch.ant-switch-checked{
    background: $color-success;
    background-image: none;
}
.add_icon{
    color:  $color-blue;
    border: 1px solid $color-blue;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.vendor .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,.vendor  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
  border: 2px solid green;
}

.vendor .fileContainer{
    box-shadow:  none;
    margin:0 ;
    padding: 0;
}

.my-switch.ant-switch{
    min-width: 100px;
}