@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.dark-theme .site-layout .site-layout-background,
.dark-theme .ant-layout {
  background: #161e33 !important;
  color: #fff !important;
}

.dark-theme .ant-alert-error,
.dark-theme .ant-alert-content div {
  background: #df5454;
  color: #FFFFFF !important;
}

.dark-theme .ant-table-cell,
.dark-theme .ant-table-cell .ant-badge {
  color: #fff !important;
}

.dark-theme .ant-table-row:hover .ant-table-cell,
.dark-theme tr.ant-table-expanded-row > td,
.dark-theme tr.ant-table-expanded-row:hover > td {
  background-color: #3b425c !important;
}

.dark-theme .ant-tabs-nav-wrap,
.dark-theme .jurisdiction .ant-tabs-nav-wrap {
  background-color: #272e48;
}

.dark-theme .app-table .ant-table-wrapper {
  background-color: #272e48 !important;
}

.dark-theme label {
  color: #FFFFFF !important;
}

.dark-theme form .ant-switch-checked {
  background-color: #307BF6 !important;
}

.dark-theme header {
  background-color: #272e48 !important;
}

.dark-theme .bg-white {
  background: #272e48 !important;
  border-color: #272e48 !important;
}

.dark-theme svg {
  color: #FFFFFF !important;
}

.dark-theme .border-danger {
  color: red !important;
}

.dark-theme .test-svg svg {
  color: red !important;
}

.dark-theme .avtar-name {
  color: #FFFFFF !important;
}

.dark-theme .accordion-body {
  background: #272e48 !important;
}

.dark-theme .Accordianheader button,
.dark-theme .ant-upload.ant-upload-drag {
  background: #3b425c !important;
}

.dark-theme .ant-upload-drag-container p {
  color: #FFFFFF !important;
}

.dark-theme .ant-tabs-content-holder .card {
  background: #272e48 !important;
}

.dark-theme .secondary-md-button {
  background: #3b425c !important;
}

.dark-theme .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #3b425c !important;
}

.dark-theme .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #272e48 !important;
  color: #FFFFFF !important;
  border: 1px solid #3b4150 !important;
}

.dark-theme .ant-table-thead > tr > th {
  background-color: #3b425c !important;
}

.dark-theme .dark-theme .ant-table-thead > tr > th,
.dark-theme .ant-table,
.dark-theme .ant-layout-sider,
.dark-theme .ant-menu.ant-menu-dark,
.dark-theme .ant-menu-dark .ant-menu-sub,
.dark-theme .ant-menu.ant-menu-dark .ant-menu-sub,
.dark-theme td.ant-table-column-sort {
  background-color: #272e48 !important;
}

.dark-theme .ant-select-dropdown.ant-select-dropdown-placement-topLeft.ant-select-dropdown-hidden {
  background-color: red !important;
}

.dark-theme .ant-pagination button {
  background-color: #272e48 !important;
  border: 1px solid #3b4150 !important;
}

.dark-theme .ant-pagination-item {
  border: 1px solid #3b4150 !important;
}

.dark-theme .ant-pagination button .anticon {
  color: wheat;
}

.dark-theme .ant-pagination-item {
  background-color: #272e48 !important;
  color: wheat;
}

.dark-theme .ant-pagination-item-active a {
  color: #FFFFFF;
}

.dark-theme .ant-dropdown-menu {
  background: #272e48 !important;
}

.dark-theme .ant-dropdown-menu-title-content > a {
  color: #FFFFFF !important;
}

.dark-theme .ant-table-filter-dropdown-btns,
.dark-theme .ant-dropdown-menu-item-selected {
  background-color: #272e48;
}

.dark-theme .ant-table-filter-dropdown-btns button,
.dark-theme .ant-dropdown-menu-title-content span {
  color: #FFFFFF !important;
}

.dark-theme .ant-modal-body,
.dark-theme h6,
.dark-theme .heading-1 {
  color: #FFFFFF !important;
}

.dark-theme .ant-input-affix-wrapper {
  background-color: #3b425c !important;
  border: 1px solid #272e48 !important;
}

.dark-theme .treeselect-input-box input,
.dark-theme .rc-color-picker-trigger {
  border: none !important;
  background: none !important;
}

.dark-theme .form-control,
.dark-theme option,
.dark-theme {
  color: #FFFFFF !important;
  background-color: #3b425c;
  border-bottom: 1px solid #3b4150 !important;
}

.dark-theme input::-moz-placeholder {
  color: #E1E1E1 !important;
}

.dark-theme input::placeholder {
  color: #E1E1E1 !important;
}

.dark-theme .ant-input-prefix {
  color: #FFFFFF;
}

.dark-theme .ant-input::-moz-placeholder {
  background-color: #3b425c !important;
  color: #c7c7c7 !important;
  border: none !important;
}

.dark-theme .ant-input::placeholder,
.dark-theme .ant-input {
  background-color: #3b425c !important;
  color: #c7c7c7 !important;
  border: none !important;
}

.dark-theme .my-account input {
  color: #FFFFFF;
}

.dark-theme .primary-md-button {
  border: 1px solid #3b425c !important;
}

.dark-theme input:invalid[focused=true] {
  background-color: #3b425c !important;
}

.dark-theme .input-field::-moz-placeholder, .dark-theme .treeselect-input-box input::-moz-placeholder {
  color: #777777 !important;
}

.dark-theme .input-field::placeholder,
.dark-theme .treeselect-input-box input::placeholder,
.dark-theme .search-icon-tree svg {
  color: #777777 !important;
}

.dark-theme .input-field,
.dark-theme .treeselect-input-box input {
  color: #FFFFFF !important;
}

.dark-theme .ant-tree .ant-tree-node-content-wrapper {
  color: #FFFFFF !important;
}

.dark-theme .ant-tree .ant-tree-node-content-wrapper:hover {
  background: #307BF6 !important;
}

.dark-theme .ant-tree {
  background-color: #3b425c !important;
  color: #FFFFFF !important;
}

.dark-theme .ant-modal-content {
  background-color: #272e48 !important;
}

.dark-theme .secondary-md-button {
  background: #3b425c !important;
  color: #FFFFFF !important;
}

.dark-theme .ant-tree-list {
  background-color: #3b425c !important;
}

.dark-theme .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal .ant-tree-title {
  color: #FFFFFF !important;
}

.dark-theme .sidebar-icon .trigger {
  background-color: #3b425c !important;
}

.dark-theme .darkmode-toggle {
  background-color: #3b425c !important;
}

.dark-theme span.ant-radio + * {
  color: #FFFFFF;
}

.dark-theme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.dark-theme .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  background-color: #272e48;
}

.dark-theme .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFFFF;
}

.dark-theme .settings-container {
  color: #FFFFFF;
}

.dark-theme .add_icon {
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}

.dark-theme .ant-typography svg {
  color: #FFFFFF !important;
}

.dark-theme h5 {
  color: #FFFFFF;
}

.dark-theme label {
  color: #FFFFFF;
}

.dark-theme select.form-control,
.dark-theme select:invalid[focused=true] {
  background: none;
}

.dark-theme .anticon {
  color: #FFFFFF;
}

/* Track */
.dark-theme ::-webkit-scrollbar-track {
  background: #3b425c !important;
  visibility: hidden !important;
}

/* Handle */
.dark-theme ::-webkit-scrollbar-thumb {
  background: #6fc6a6 !important;
  visibility: hidden !important;
}

.dark-theme .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.dark-theme .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #272e48 !important;
}

.dark-theme .k-editor {
  background-color: #272e48;
}

.dark-theme .k-toolbar {
  background: #3b425c;
}

.dark-theme .k-toolbar button,
.dark-theme .k-picker-solid,
.dark-theme .k-content p {
  background-color: #272e48 !important;
  color: #FFFFFF !important;
}

.dark-theme .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  background-color: #272e48 !important;
}

.dark-theme .annotation .left-bar {
  background-color: #272e48;
}

.dark-theme .btn-light {
  background: none !important;
  color: #FFFFFF;
}

.dark-theme p {
  color: #fff !important;
}

.dark-theme .secondary-bg-color {
  background: #272e48 !important;
}

.dark-theme .bg-light-yello-200 {
  background: #272e48 !important;
}

.dark-theme .ant-collapse-content {
  background: #272e48 !important;
}

.dark-theme .bg-light-blue-300 {
  background: #272e48 !important;
}

.dark-theme span {
  color: #FFFFFF;
}

.dark-theme select {
  background: none !important;
  color: #FFFFFF;
}

.dark-theme .rsw-btn {
  color: #FFFFFF;
}

.dark-theme .rsw-toolbar {
  align-items: center;
  background-color: #272e48 !important;
  border-bottom: 1px solid #ddd;
  display: flex;
}

.dark-theme .rsw-ce {
  color: #FFFFFF !important;
  background-color: #272e48 !important;
}

.dark-theme .input-icon {
  color: #FFFFFF !important;
}

.dark-theme .ant-btn {
  color: #FFFFFF;
}

.dark-theme .cancel-border {
  border: 1px solid #3b4150 !important;
}

.dark-theme .modal-close .confirm-box {
  background: #272e48 !important;
  color: #FFFFFF !important;
}

.dark-theme .ant-collapse {
  background-color: #272e48 !important;
  border-color: #353e46 !important;
}

.dark-theme .text-dark {
  color: #FFFFFF !important;
}

.dark-theme .ant-empty-normal {
  color: #FFFFFF !important;
}

.dark-theme .ant-empty {
  background: #272e48 !important;
  padding: 0 !important;
}

.dark-theme .ant-table-tbody > tr > td,
.dark-theme.ant-table tfoot > tr > th,
.dark-theme .ant-table tfoot > tr > td {
  background: #272e48 !important;
}

.dark-theme .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #FFFFFF !important;
}

.dark-theme .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.dark-theme .ant-tabs-card > div > .dark-theme .ant-tabs-nav .ant-tabs-tab {
  background: #272e48 !important;
  border-color: #343e47 !important;
}

.dark-them .ant-tabs-nav-more .ant-tabs-dropdown-open {
  background: #272e48 !important;
  color: #FFFFFF !important;
}

.dark-theme .border {
  border: 1px solid #3b4150 !important;
}

.dark-theme .ant-select-multiple .ant-select-selection-item {
  background: #272e48 !important;
}

.dark-them .ant-select-multiple {
  border: 1px solid #6c757d !important;
}

.dark-theme .my-multiselect {
  background-color: #272e48 !important;
  color: #FFFFFF !important;
}

.dark-theme.ant-select-selection-overflow {
  background-color: #272e48 !important;
  color: #FFFFFF !important;
}

.dark-theme .ant-select-dropdown {
  background-color: #272e48 !important;
}

.dark-theme .ant-dropdown-menu-item:hover,
.dark-theme .ant-dropdown-menu-submenu-title:hover,
.dark-theme .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.dark-theme .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.dark-theme .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.dark-theme .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: #0d6efd !important;
}

.dark-theme .rsw-btn:hover {
  background-color: #3b425c !important;
}

.dark-theme .rsw-btn[data-active=true] {
  background: #3b425c !important;
}

.dark-theme .bill-table {
  border-block-start: 1px solid white;
}

.dark-theme .input[type=date],
.dark-theme input[type=time],
.dark-theme input[type=datetime-local],
.dark-theme input[type=month],
.dark-theme .treeselect-input-box input {
  color: #ffffff !important;
}

.dark-theme input {
  color-scheme: dark;
}

.dark-theme .ant-modal-body {
  background: #272e48 !important;
}

.dark-theme tbody,
.dark-theme td,
.dark-theme tfoot,
.dark-theme th,
.dark-theme thead,
.dark-theme tr {
  color: #FFFFFF !important;
}

.dark-theme .ant-dropdown-menu-item {
  background-color: #272e48 !important;
}

.dark-theme .ant-dropdown-menu-item-selected,
.dark-theme .ant-dropdown-menu-submenu-title-selected {
  color: #1890ff;
  background-color: #112537 !important;
}

.dark-theme .ant-table-tbody > tr > td {
  border-bottom: 1px solid #3b4150 !important;
}

.dark-theme .card {
  border: 1px solid #3b4150 !important;
}

.dark-theme .card-body {
  background-color: #272e48 !important;
}

.dark-theme input {
  border-bottom: 2px solid #3b4150 !important;
}

.dark-theme textarea {
  border: 2px solid #3b4150 !important;
}

.dark-theme select.form-control1 {
  border-bottom: 2px solid #3b4150 !important;
}

.dark-theme .form-control1,
.dark-theme option,
.dark-theme {
  color: #FFFFFF !important;
  background-color: #3b425c;
}

.dark-theme .treeselect-input-box {
  border: 2px solid #3b4150 !important;
}

.dark-theme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.dark-theme .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more .ant-tabs-dropdown-open {
  background-color: #272e48 !important;
}

.dark-theme .ant-tabs-dropdown-menu {
  background-color: #272e48 !important;
}

.dark-theme .ant-tabs-dropdown {
  background-color: #272e48 !important;
}

.dark-theme .ant-tabs-dropdown-menu-item:hover {
  background: #182139 !important;
}

.dark-theme .ant-steps-item-wait .ant-steps-item-icon {
  background: #092f50 !important;
  color: #f8f9fa !important;
}

.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ark-theme .ant-steps-icon {
  color: #f8f9fa !important;
}

.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #f8f9fa !important;
}

.dark-theme .bg-light {
  background-color: #272e48 !important;
}

.dark-theme .tax-card-icon {
  background-color: #272e48 !important;
  border: 2px solid #FFFFFF !important;
}

.dark-theme .ant-table-filter-dropdown {
  background-color: #272e48 !important;
}

.dark-theme .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  background-color: #272e48 !important;
}

.dark-theme .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.dark-theme .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #182139 !important;
}

.dark-theme .ant-menu-inline-collapsed-tooltip a,
.dark-theme .ant-menu-inline-collapsed-tooltip a:hover {
  text-decoration: none !important;
}

.dark-theme .ant-popover-inner {
  background-color: #272e48 !important;
}

.dark-theme .ant-popover-message-title {
  color: #FFFFFF !important;
}

.dark-theme .ant-notification-notice {
  background-color: #0e1322 !important;
}

.dark-theme .ant-drawer-body {
  background-color: #272e48 !important;
}

.dark-theme .ant-drawer-header {
  background-color: #272e48 !important;
}

.dark-theme .ant-layout-footer {
  color: #FFFFFF !important;
}

.dark-theme .status-cards-row .dash-cards {
  background-color: #17203f !important;
  color: #FFFFFF !important;
  border: 1px solid #021729 !important;
}

.dark-theme .status-cards-row .header-title-open {
  color: #FFFFFF !important;
}

.dark-theme .h1,
.dark-theme .h2,
.dark-theme .h3,
.dark-theme .h4,
.dark-theme .h5,
.dark-theme .h6,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
  color: #FFFFFF !important;
}

.dark-theme .dounut-barchart-row .ant-tabs-nav-wrap {
  background-color: #040d2e !important;
  border-radius: 0px !important;
}

.dark-theme .dounut-barchart-row {
  background-color: #040d2e !important;
  border: none !important;
}

.dark-theme .card {
  background-color: #040d2e !important;
  border: 1px solid #191e22 !important;
}

.dark-theme .dounut-barchart-row .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.dark-theme .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #040d2e !important;
  border-radius: 0px !important;
}

.dark-theme .ant-tabs > .dark-theme .ant-tabs-nav .dark-theme .ant-tabs-nav-more,
.dark-theme .ant-tabs > div > .dark-theme .ant-tabs-nav .dark-theme .ant-tabs-nav-more {
  position: relative;
  background: #040d2e !important;
  border: 0;
}

.dark-theme .total-count-card {
  background-color: #17203f !important;
  color: #FFFFFF !important;
  border: none !important;
}

.dark-theme .upload button {
  background-color: #051138 !important;
  color: #FFFFFF !important;
  border: none !important;
}

.dark-theme .dounut-barchart-row .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  background-color: #040d2e !important;
}

.dark-theme .ant-table-wrapper .ant-table-row-expand-icon {
  background: none !important;
  border: 1px solid #555b6b !important;
}

.dark-theme .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td {
  /* border-top: 1px solid #f0f0f0; */
  border-top: #3b4150 !important;
}

.dark-theme .ant-popconfirm .ant-popconfirm-message-title {
  color: #FFFFFF !important;
}

.dark-theme .ant-tabs .ant-tabs-tabpane {
  color: #FFFFFF !important;
}

.dark-theme .order .ant-drawer-body {
  padding: 0 !important;
  border-top: 1px solid #474d5c !important;
}

.dark-theme .ant-select-multiple .ant-select-selection-placeholder {
  color: #FFFFFF !important;
}

.dark-theme .ant-btn-primary:disabled {
  background-color: #6a7083 !important;
}

.dark-theme .add-property {
  color: #5b9efc !important;
}

.dark-theme .text-primary {
  background-color: none !important;
  color: #5b9efc !important;
}

.dark-theme button.filter-btn.ant-btn.ant-btn-default {
  background: none !important;
  border: 1px solid #4c4f5f !important;
}

.dark-theme button.reset-btn.ant-btn.ant-btn-default {
  background: none !important;
  border: 1px solid #4c4f5f !important;
}

.dark-theme button.upload-btn.ant-btn.ant-btn-default {
  background: #198754 !important;
  border: 1px solid #198754 !important;
}

.dark-theme .sort-svg svg {
  color: #307bf6 !important;
}

.dark-theme .ant-select-dropdown .ant-select-item-option-content {
  color: #FFFFFF !important;
}

.dark-theme .ant-select-dropdown .ant-select-item-option {
  display: flex;
  color: #FFFFFF !important;
}

.dark-theme .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #131f41;
}

.dark-theme .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #FFFFFF !important;
}

.dark-theme .ant-table-filter-dropdown .ant-space-item:first-child button {
  background: none !important;
}

.dark-theme .file .file-button:hover {
  background-color: #0d86ff;
}/*# sourceMappingURL=DarkMode.css.map */