@import "../../Style/Variable/Variable.scss";

.orderheader {
  @include font($font-family, "font_13", medium, $color-blue);
}
.accordion-button,
.accordion-button:not(.collapsed) {
  color: $color-gray2;
  background-color: $color-gray2;
  box-shadow: inset 0 -1px 0;
  opacity: 1;
  border-bottom: none !important;
  border-top-left-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}
.accordion-item {
  border: none;
}
.accordion-item:first-of-type .accordion-button:focus {
  border: none !important ;
  box-shadow: none !important;
}

.ant-table-filter-trigger {
  left: 0% !important;
  color: $color-success;
  width: 30px;
  z-index: 1;
  position: relative;
}
// .ant-tabs {
//   background-color:#fff;
// }

/* width */
.ant-table-content ::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.ant-table-content ::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.ant-table-content ::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.ant-table-content ::-webkit-scrollbar-thumb:hover {
	background: #555;
}
