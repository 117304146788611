@import "../../Style/Variable/Variable.scss";

.primary-button{
    @include b-button($color-blue, 4px 15px, 7px);
    // min-width: 482px
    @include font($font-family, "font_18", medium, $color-white);
    width: 100%;
};

.secondary-md-button{
    @include b-button($color-lightgray,  4px 40px, 4px);
    // min-width: 482px
    @include font($font-family, "font_15", medium, $colo-black1);
    width: 100%;
};
.primary-md-button{
    @include b-button($color-blue,  4px 40px, 4px);
    // min-width: 482px
    @include font($font-family, "font_15", medium, $color-white);
    width: 100%;
    
};
.disabled{
    cursor:not-allowed;
}
.round-active-button {
	@include b-button($color-success, 4px 15px, 15px);

	// min-width: 482px
    cursor: none !important;
	@include font($font-family, "font_12", normal, $colo-black1);
}

.round-inactive-button {
	@include b-button($color-red, 4px 15px, 15px);

	// min-width: 482px
    cursor: none !important;
	@include font($font-family, "font_12", normal, $colo-black1);
}

.edit-buttons{
    @include font($font-family, "font_12", normal, $color-white);
}