.ql-size-small {
    font-size: 12px;
  }
  
  .ql-size-medium {
    font-size: 16px;  
  }
  
  .ql-size-large {
    font-size: 24px;
  }
  
  .ql-size-huge {
    font-size: 36px;
  }
  