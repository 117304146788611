@import "../../../Style/Variable/Variable.scss";

.juri-code-content{
    display: none;
    z-index: 100;
    // width: 50px;
    margin-left: 13%;
    border: 1px solid $color-success;
}
.juri-code:hover .juri-code-content {
  display: block;
}
.juri-code-content span{
    font-size: 9px;
    font-weight: 500;
}