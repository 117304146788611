@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.formInput {
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-top: 20px;
  float: left;
  /* margin-right: 40px; */
}

.select_icon {
  position: absolute;
  top: 8px;
  right: 0;
  color: #777777;
}

select.form-control {
  padding: 11px 4px;
  padding-bottom: 8px;
  border: none;
  margin-top: 2px !important;
  border-bottom: 2px solid #CACACA;
  border-radius: 0.15em;
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

select.form-control1 {
  padding: 11px 4px;
  padding-bottom: 8px;
  border: none;
  margin-top: 2px !important;
  border-bottom: 2px solid #CACACA;
  border-radius: 0.73em;
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

select.form-control:focus {
  box-shadow: none;
  border: none;
  outline: none;
  border-bottom: 2px solid #307BF6 !important;
}

label {
  /* font-size: 14px; */
  color: #777777;
}

.input-error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

select:active {
  border-bottom: 2px solid #307BF6 !important;
}

select:invalid[focused=true],
.not-avail {
  border-bottom: 1px solid red;
  border-radius: 0.73rem !important;
}
select[focused=true] {
  border-radius: 0.73rem !important;
}

.not-avail {
  cursor: not-allowed;
}

select:invalid[focused=true] ~ span {
  display: block;
}

/* select:not([type="button"]):not([type="submit"]):not([type="reset"]):hover, select:not([type="button"]):not([type="submit"]):not([type="reset"]):focus, textarea:hover, textarea:focus, select:hover, select:focus {
    border-color: #81256f;
    box-shadow: none;
} */
select:focus,
select:active,
select[focused=true] {
  border-bottom-color: #40a9ff;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, .2); */
  outline: 0;
}

.alertIcon {
  padding-right: 0.17647rem;
  top: 0.17647rem;
}

.disable-input select:focus,
.disable-input select:active,
.disable-input input[focused=true] {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 2px solid #CACACA !important;
}

.disable-input input {
  cursor: not-allowed;
}

.form-control1:disabled,
.form-control1[readonly] {
  background: none !important;
  opacity: 1;
}

select.form-control:disabled, select.form-control {
  background: none !important;
  opacity: 1;
  border-radius: 0.73rem !important;
}/*# sourceMappingURL=AppSelect.css.map */
.app-search-select .ant-select-selector{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #d9d9d9 !important;
  border-radius: 0px !important;
 }
 .app-search-select:focus .ant-select-selector:focus{
  box-shadow: none !important;
  border-bottom: 3px solid #4096ff !important;
 }
 #root + div + .ant-select-dropdown {
  display: none;
}