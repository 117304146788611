@import "../../../Style/Variable/Variable.scss";
.ant-table-filter-trigger{
    left: -47% !important;
    color: $color-success;
    width: 25px;
    z-index: 10;
    position: relative;
  }
    .editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

 .ant-table-filter-trigger:hover{
    color: $color-success;
  }