@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.rdrDateDisplayItemActive input {
  color: #7d888d;
  margin-top: 5px !important;
  padding: 0px !important;
}

.rdrDateDisplayItem input {
  margin-top: 5px !important;
  padding: 0px !important;
}

.rdrDateRangePickerWrapper {
  width: 100% !important;
  border: 1px solid #CACACA !important;
  padding: 1px !important;
}

.rdrDefinedRangesWrapper {
  width: 100px !important;
  padding: 0;
  text-align: center !important;
}

.rdrStaticRangeLabel {
  padding: 8px 0px !important;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 288px !important;
}

.rdrInputRange {
  align-items: center;
  padding: 0px !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
}

.rdrInputRangeInput {
  width: 21px !important;
  height: 30px;
  line-height: 10px !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  margin-left: 1px !important;
}

.rdrStaticRangeLabel {
  line-height: 8px !important;
  text-align: center !important;
  font-weight: 500;
}

.rdrMonths.rdrMonthsHorizontal {
  display: block !important;
}

.rdrMonth {
  width: 22.667em !important;
  line-height: 0px !important;
  margin-bottom: 5px !important;
  border: 1px solid #eff2f7 !important;
  height: 230px !important;
}

.rdrCalendarWrapper {
  font-size: 12px !important;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 32px !important;
  padding-top: 0px;
}

.rdrMonthName {
  font-weight: 700 !important;
}/*# sourceMappingURL=AppDateRangePicker.css.map */