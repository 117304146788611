@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.primary-button {
  background-color: #307BF6;
  padding: 4px 15px;
  border: 1px solid #307BF6;
  border-radius: 7px;
  
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 600;
  width: 100%;
}

.secondary-md-button {
  background-color: #CACACA;
  padding: 4px 40px;
  border: 1px solid #CACACA;
  border-radius: 4px;
  
  font-size: 15px;
  color: #4F4F4F;
  font-weight: 600;
  width: 100%;
}

.primary-md-button {
  background-color: #307BF6;
  padding: 4px 40px;
  border: 1px solid #307BF6;
  border-radius: 4px;
  
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 600;
  width: 100%;
}

.disabled {
  cursor: not-allowed;
}

.round-active-button {
  background-color: #7CFFA7;
  padding: 4px 15px;
  border: 1px solid #7CFFA7;
  border-radius: 15px;
  cursor: none !important;
  
  font-size: 12px;
  color: #4F4F4F;
  font-weight: 500;
}

.round-inactive-button {
  background-color: red;
  padding: 4px 15px;
  border: 1px solid red;
  border-radius: 15px;
  cursor: none !important;
  
  font-size: 12px;
  color: #4F4F4F;
  font-weight: 500;
}

.edit-buttons {
  
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 500;
}/*# sourceMappingURL=AppButton.css.map */