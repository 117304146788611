
@import "../../Style/Variable/Variable.scss";
.dashboard-cards .dash-cards {
    border-radius: 10px !important;
  }



.status-cards-row .dash-cards{
    // display:flex;
    // max-width: 1200px;
    // flex-wrap: wrap;
    // border-radius: 10px;
    // border-color: black !important;
    // height: 150px;
    padding: 5 !important;
    cursor: pointer;
  }

//   .openImg{
//     color: #cf6b13;
//     fill: rgba(235, 116, 12, 0.25);
// }

  .openImg{
    color: #0b2253;
    // fill: rgba(148, 205, 243, 0.25);
}
  
.status-cards-row .col {
   
        flex: 1 0;
     padding: 0px !important;
     margin:  0px !important;
     border-radius: 10px !important;
    
}
.status-cards-row .col .dash-cards{
     padding: 10px !important;
    padding-left: 10px !important;
    justify-content: center !important;
   
}

.status-cards-row .title-text, .header-title {
    margin-bottom: 8px;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0;
    color: #091331;
    // text-shadow: 0 0 1px rgb(241 245 250 / 10%);
    font-family: "Roboto",sans-serif;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

}


.status-cards-row  .header-title-open {
    // margin-bottom: 8px;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    font-size: 15px;
    font-weight: bold;
    margin-top: 0;
    color: #383737;
    // text-shadow: 0 0 1px rgb(241 245 250 / 10%);
    font-family: "Montserrat";
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

}

.status-cards-row  .header-title-open >h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    color: #092F50; 
    font-family: "Montserrat";
}

.status-cards-row .title-text, .header-title-open {
    margin-bottom: 8px;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0;
    color: #303e67;
    // text-shadow: 0 0 1px rgb(241 245 250 / 10%);
    font-family: "Roboto",sans-serif;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: pre;

}





.douchart .status-table{
    margin: 0 !important;
    width: 200 !important;
    height: 200 !important;
}

.douchart .pie-chart{
    vertical-align: top !important;
}

.status-cards-row .dash-cards{
    border-radius: 10px!important;
    background-color: #eff8 !important;
}


.pie-chart-col .bar-chart{
    margin-top: 0 !important;
    width: 200 !important;
    height: 200 !important;
    padding: 0 !important;
}

.dounut-barchart-row{
   border-top: 5px solid rgb(34, 34, 83) !important; 
   border-radius: 10px !important;
   padding: 10px !important;

}

.total-count-card{
    background-color:#EFF8FF !important ;
    font-weight: bold;
    font-size: 14px;
    color: #061238;
}

.dounut-barchart-row .ant-tabs-nav-wrap{
    //  border-radius: 5px !important;
     border: none !important;
    background-color: white !important;
  display: flex;
  justify-content: center;
}

.dounut-barchart-row .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    background: #EFF8FF !important;
    border-radius: 15px !important;
    padding: 5px !important;
}

.dounut-barchart-row .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    font-size: 14px !important;
    width: 14.5rem !important;
    text-align: center;
}

.nodata-card{
    height: 480px !important;
}
.monthly-table{
    overflow-y:scroll !important;
     max-width: fit-content;
}

.monthly-table .app-table .ant-table-wrapper {
  box-shadow: 0px 0px 0px   $color-shadow !important;
  background-color: $color-white !important;
}