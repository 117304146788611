

:where(.css-dev-only-do-not-override-k83k30).ant-badge .ant-badge-count, :where(.css-dev-only-do-not-override-k83k30).ant-badge .ant-badge-dot, :where(.css-dev-only-do-not-override-k83k30).ant-badge .ant-scroll-number-custom-component{
  width: 10px;
    margin-left: -6px;
    display: inline-block;
    position: relative;
    margin-top:10px
}
.ant-scroll-number, .ant-badge-count , .ant-badge-count-sm{
  right: 0% !important;

}
:where(.css-k83k30).ant-badge .ant-badge-count, :where(.css-k83k30).ant-badge .ant-badge-dot, :where(.css-k83k30).ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 5px !important;
  left: -27px !important;
  width: 15px !important;
  inset-inline-end: 0;
  /* transform: translate(50%, -50%); */
  /* transform-origin: 100% 0%; */
}

/* //certificate css  */
.dbheader{
  width: 100%;
  padding: 10px;
  top: 1px;
  left: 0;
  right: 0;
  position: absolute;
  width: 98%;
  margin: auto;


}
.text-orange{
	color: #F24C3D !important
}
.dbheader header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order .ant-tabs-nav-wrap{
padding: 0.5rem;
}