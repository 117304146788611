@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.juri-code-content {
  display: none;
  z-index: 100;
  margin-left: 13%;
  border: 1px solid #7CFFA7;
}

.juri-code:hover .juri-code-content {
  display: block;
}

.juri-code-content span {
  font-size: 9px;
  font-weight: 500;
}/*# sourceMappingURL=TaxCollector.css.map */