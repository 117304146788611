.header-conetent span{
 font-size: 7px;
 font-weight: 600;
 /* line-height: 2px; */
 display: block;
}
.cert-border-bottom{
    border-bottom: 1px solid #808080;
}
.hoa-certificate .content{
    border: 1px solid #808080;
}
.hoa-certificate .wrap-heading{
    height: 12px;
}
.hoa-certificate .cert-heading{
    font-size: 7px;
    font-weight: 700;
}
.hoa-certificate .cert-desc{
    font-size: 6px;
    font-weight: 500;
}
.hoa-certificate .statement table th{
    width: 120px;
    padding-bottom: 3px;
}
.cert-desc-line {
    border-bottom: 0.8px solid #808080;
    display: inline-block;
 
}
.special-comments {
    min-height: 150px;
}