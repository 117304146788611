@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");

/* Form Input Container */
.formInput {
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-top: 20px;
  float: left;
}

/* Input Fields */
input {
  /* padding: 13px 6px;  */
  /* margin-top: 20px; */
  border: none;
  border-bottom: 2px solid lightgray;
  font-weight: 500;
  font-family: inherit;
  border-radius: 0.75em !important; /* Retained larger border-radius */
}

input[name="password"] {
  padding-left: 35px !important;
  margin-top: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

input:focus,
input:active,
input[focused="true"] {
  border-bottom-color: #40a9ff;
  outline: 0;
}

input:invalid[focused="true"] {
  border-bottom: 1px solid red;
  background-color: #fff2f4;
}

input:invalid[focused="true"] ~ span,
.k-invalid ~ span {
  display: block;
}

input:invalid[focused="true"] ~ .input-icon {
  color: red;
}

/* Placeholder Styling */
input::placeholder,
input::-moz-placeholder,
input[type="file"]::placeholder,
input[type="file"]::-moz-placeholder {
  
  font-size: 12px;
  color: #cacaca !important;
  font-weight: 600;
}

/* Input Icon */
.input-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  font-size: 15px;
}

.user-modal .input-icon {
  top: 40px;
  padding-left: 10px;
  right: 0;
}

.icon-input {
  padding-left: 35px !important;
  margin-top: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.icon-dark {
  color: #4f4f4f;
}

.passIcon {
  cursor: pointer;
}

/* Custom File Input */
.custome-file {
  opacity: 0;
  background: red;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  left: 0;
}

/* File Button */
.file-button {
  margin-top: 10px;
  display: inline-block;
  background: rgb(247, 248, 249);
  padding: 3px 15px;
  border: 1px solid #4f4f4f;
  font-weight: 600;
}

/* Input Errors */
.input-error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
  /* margin-bottom: 10px; */
}

/* Disabled Input */
.disable-input input {
  cursor: not-allowed;
}

.disable-input input:focus,
.disable-input input:active,
.disable-input input[focused="true"] {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 2px solid #cacaca !important;
}

/* Input Field Styling */
.input-field {
  background: none;
}

.input-field input {
  margin-bottom: 10px;
  padding-bottom: 10px !important;
  font-family: inherit;
  font-size: 12px !important;
  line-height: inherit;
}

/* Alert Icon */
.alertIcon {
  padding-right: 0.17647rem;
  top: 0.17647rem;
}

/* App Input Specific Styles */
.app-input .k-input-solid {
  background: none !important;
}

.app-input .k-button-solid-base {
  background: none !important;
  border: none !important;
}

.app-input .anticon {
  margin-top: 18px;
}

.app-input :where(.css-dev-only-do-not-override-k83k30).ant-picker-focused,
.app-input :where(.css-dev-only-do-not-override-k83k30).ant-picker {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0.73rem !important;
  border-bottom: 2px solid #d9d9d9 !important;
  width: 100% !important;
  background: none !important;
}

.app-input input[placeholder="MM/DD/YYYY"] {
  border-radius: 0px !important;
  border: none !important;
  width: 100% !important;
  background: none !important;
  border-radius: 0.73rem !important;
}
