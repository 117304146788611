@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.login {
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logoImage {
  width: 60%;
  margin-bottom: 50px;
}

.login-input-field input {
  width: 100%;
}

.lereta-primary{
  color: #222b54;
}

.lereta-bg-primary{
  background-color: #222b54 !important;
  border: none !important;
}

input:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

input::-moz-placeholder {
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

input::placeholder {
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

.forgot-password {
  
  font-size: 12px;
  color: #307BF6;
  font-weight: 600;
  text-decoration: none;
}/*# sourceMappingURL=Login.css.map */

@media (min-width: 576px) {
  .img-container img{
    height: 100vh !important;
  }
  
}