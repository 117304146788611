@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.ant-table-cell .ant-badge {
  
  font-size: 12px;
  color: #777777;
  font-weight: 600;
}

.ant-badge .ant-scroll-number {
  
  font-size: 11px;
  color: #FFFFFF;
  font-weight: 500;
}

.ant-table-cell {
  
  font-size: 11px;
  color: #777777;
  font-weight: 600;
}

.ant-table-column-title {
  
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 600;
}

.b-btn1 {
  background-color: #E1E1E1;
  padding: 4px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  
  color: #4F4F4F;
  font-weight: 600;
}

.btn-Open {
  background-color: #FF9A9A;
  padding: 5px 15px;
  border: 1px solid #FF9A9A;
  border-radius: 10px;
  
  color: #4F4F4F;
  font-weight: 600;
  min-width: 80px;
}

.btn-Pending {
  background-color: #FFF08D;
  padding: 2px 15px;
  border: 1px solid #FFF08D;
  border-radius: 10px;
  
  color: #4F4F4F;
  font-weight: 600;
  min-width: 80px;
}

.status-button {
  background-color: #FFF08D;
  padding: 2px 15px;
  border: 1px solid #FFF08D;
  border-radius: 10px;
  
  color: #4F4F4F;
  font-weight: 600;
  min-width: 140px;
}

.btn-Assigned {
  background-color: #7CFFA7;
  padding: 2px 15px;
  border: 1px solid #7CFFA7;
  border-radius: 10px;
  
  color: #4F4F4F;
  font-weight: 600;
  min-width: 80px;
}

.ant-table-row-expand-icon {
  background-color: #307BF6;
  border: 1px solid #307BF6;
  
  font-size: 11px;
  color: #FFFFFF;
  font-weight: 900;
}

.ant-table-row-expand-icon::after {
  width: 1.2px;
}

.row-expand-icons {
  margin-left: 40px;
}

.row-expand-icons > li img {
  cursor: pointer;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
  margin: 0;
  padding: 5px 16px;
}

.ant-table-cell {
  
  color: #777777;
  font-weight: 500;
}

.ant-table-thead > tr > th {
  background: #092F50 !important;
}

.ant-table-column-sorter-inner {
  display: none;
}

.ant-table-filter-trigger {
  left: -12% !important;
  color: #7CFFA7;
  width: 25px;
  z-index: 10;
}

.ant-table-filter-trigger:hover {
  color: #7CFFA7;
}

.ant-table-row-expand-icon {
  background-color: #307BF6;
  border: 1px solid #307BF6;
  
  color: #FFFFFF;
  font-weight: 900;
}

.ant-table-row-expand-icon::after {
  width: 1.2px;
}

.row-expand-icons {
  margin-left: 40px;
}

.row-expand-icons > li img {
  cursor: pointer;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
  margin: 0;
  padding: 5px 16px;
}/*# sourceMappingURL=AppDragTable.css.map */