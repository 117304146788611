@import "../../Style/Variable/Variable.scss";

.ant-table-cell .ant-badge {
  @include font($font-family, "font_12", medium, $color-gray);
}
.ant-badge .ant-scroll-number {
  @include font($font-family, "font_11", normal, $color-white);
}
.ant-table-cell {
  @include font($font-family, "font_11", medium, $color-gray);
}
.ant-table-column-title {
  @include font($font-family, "font_12", medium, $color-white);
}

.b-btn1 {
  @include b-button($color-gray2, 4px, 10px);
  @include font($font-family, 15px, medium, $colo-black1);
}

.btn-Open {
  @include b-button($color-coral, 5px 15px, 10px);
  @include font($font-family, 11px, medium, $colo-black1);
  min-width: 80px;
}
.btn-Pending {
  @include b-button($colo-mustard, 2px 15px, 10px);
  @include font($font-family, 11px, medium, $colo-black1);
  min-width: 80px;
}
.status-button {
  @include b-button($colo-mustard, 2px 15px, 10px);
  @include font($font-family, 11px, medium, $colo-black1);
  min-width: 140px;
}
.btn-Assigned {
  @include b-button($color-success, 2px 15px, 10px);
  @include font($font-family, 11px, medium, $colo-black1);
  min-width: 80px;
}

.ant-table-row-expand-icon {
  background-color: $color-blue;
  border: 1px solid $color-blue;
  @include font($font-family, "font_11", bold, $color-white);
}
.ant-table-row-expand-icon::after {
  width: 1.2px;
}
.row-expand-icons {
  margin-left: 40px;
}
.row-expand-icons > li img {
  cursor: pointer;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
  margin: 0;
  padding: 5px 16px;
}

.ant-table-cell {
  @include font($font-family, 11px, normal, $color-gray);
}
.ant-table-thead > tr > th {
  background: $color-primary !important;
}
.ant-table-column-sorter-inner {
  display: none;
}

.ant-table-filter-trigger {
  left: -12% !important;
  color: $color-success;
  width: 25px;
  z-index: 10;
}
.ant-table-filter-trigger:hover {
  color: $color-success;
}

.ant-table-row-expand-icon {
  background-color: $color-blue;
  border: 1px solid $color-blue;
  @include font($font-family, 11px, bold, $color-white);
}
.ant-table-row-expand-icon::after {
  width: 1.2px;
}
.row-expand-icons {
  margin-left: 40px;
}
.row-expand-icons > li img {
  cursor: pointer;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
  margin: 0;
  padding: 5px 16px;
}
