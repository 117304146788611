@import "../../Style/Variable/Variable.scss";
.formInput {
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-top: 20px;
  float: left;
  /* margin-right: 40px; */
}

.select_icon {
  position: absolute;
  top: 8px;
  right: 0;
  color: $color-gray;
}
select.form-control {
  padding: 11px 4px;
  padding-bottom: 8px;
  border: none;
  // margin: 10px 0px;
  margin-top: 2px !important;
  border-bottom: 2px solid $color-lightgray;
  border-radius: 0.15em;
  @include font($font-family, "font_12", medium, $color-gray);
  // background-color: red !important;
}
select.form-control1 {
  padding: 11px 4px;
  padding-bottom: 8px;
  border: none;
  // margin: 10px 0px;
  margin-top: 2px !important;
  border-bottom: 2px solid $color-lightgray;
  border-radius: 0.15em;
  @include font($font-family, "font_12", medium, $color-gray);
}
select.form-control:focus {
  box-shadow: none;
  border: none;
  outline: none;
  border-bottom: 2px solid $color-blue !important;
}
label {
  /* font-size: 14px; */
  color: $color-gray;
}

.input-error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

select:active {
  border-bottom: 2px solid $color-blue !important;
}

select:invalid[focused="true"],
.not-avail {
  border-bottom: 1px solid red;
  // background-color: #fff2f4;
}
.not-avail {
  cursor: not-allowed;
}
select:invalid[focused="true"] ~ span {
  display: block;
}

/* select:not([type="button"]):not([type="submit"]):not([type="reset"]):hover, select:not([type="button"]):not([type="submit"]):not([type="reset"]):focus, textarea:hover, textarea:focus, select:hover, select:focus {
    border-color: #81256f;
    box-shadow: none;
} */

select:focus,
select:active,
select[focused="true"] {
  border-bottom-color: #40a9ff;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, .2); */
  // border-right-width: 1px;
  outline: 0;
}

.alertIcon {
  padding-right: 0.17647rem;
  top: 0.17647rem;
}
.disable-input select:focus,
.disable-input select:active,
.disable-input input[focused="true"] {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 2px solid $color-lightgray !important;
}
.disable-input input {
  cursor: not-allowed;
}

.form-control1:disabled,
.form-control1[readonly] {
  background: none !important;
  opacity: 1;
}
select.form-control:disabled,select.form-control {
  background: none !important;
  opacity: 1;
}
