@import "../../Style/Variable/Variable.scss";
 .file .ant-upload{
    width: 100% !important;
  min-width: 170px !important;
  margin: auto;

 }
.file .ant-upload-list-picture .ant-upload-list-item, .file .ant-upload-list-picture-card .ant-upload-list-item{
   height: 55px !important;
   width: 10px !important;
}
.file .file-preview{
    width: 26px;
    height: 26px;
}
.file .filename{
    @include font($font-family, "font_10", medium, $colo-black1 !important);
}
.cursor-pointer{
    cursor: pointer;
}
.file .file-button:hover{
    background-color: $color-white;
}
.file  .close-file{
    background: rgb(249, 185, 185);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    color: #fff !important;
    font-size: 10px;
}
.file .file-flag{
    margin-top: -2.5rem!important;
}