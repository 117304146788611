@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.tax-card-icon {
  color: #307BF6;
  border: 1px solid #00000029 !important;
  background-color: white !important;
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.tax-card-tab .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #CACACA !important;
  height: 38px !important;
}

.tax-card-tab .ant-tabs-nav-wrap {
  background-color: #EFF8FF;
  width: 100%;
  border-radius: 2px;
  padding: 0.2rem 0.7rem;
  -webkit-box-shadow: 0px 0px 0px #00000029 !important;
          box-shadow: 0px 0px 0px #00000029 !important;
  
  font-size: 13px;
  color: #777777;
  font-weight: 600;
}

.tax-card-tab .ant-collapse-content .ant-collapse-content-box {
  padding-bottom: 0px !important;
  padding-top: 5px !important;
  margin-top: -5px !important;
  padding: 0px;
}




.tax-card-tab .ant-tabs-tab-btn {
  font-size: 14px !important;
}

.tax-card-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: none !important;
  border: none !important;
  /* background: #fafafa; */
  /* border: 1px solid #f0f0f0; */
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
