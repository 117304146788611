@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
/* .sessionexpire {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.pagenotfound {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=SessionExpire.css.map */
.pagenotfound .description{
  font-size: 20px;
  letter-spacing:1px;
  font-family: Montserrat,sans-serif;
  line-height: 30px;
  font-weight: 600;

}
.pagenotfound-heading{
  font-weight: bold;
  font-size: 35px;
  letter-spacing:2px;
  font-family: Montserrat,sans-serif;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media  (min-width:400px){
  
   .pagenotfound-subtext{
    display:flex;
   }
}