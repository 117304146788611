.new-order-comments select{
    font-size: 14px ;
}
.search-order-list {
    max-height: 250px;
    overflow-y: auto;
}
/* .search-table input:active, .search-table input:focus{
    box-shadow: none !important;
    border:1px solid #000;
} */

.search-table li:hover{
    background:rgba(247, 242, 242, 0.648);
}

.showcurrent_comments{
    width: 200px;
    position: absolute;
    top: 0;
    z-index:22 ;
    left: 0;
    margin-left: 18px;
   font-size: 9px;
   background-color: rgba(247, 242, 242, 1);
   display: none;
}
.showcurrent_comments.active{
    display: block;
}
.order-two-view{
    max-height: 125vh;
    overflow-y: auto;
    padding:0 10px;
    overflow-x: hidden;
    left: 15px;
}

/* width */
.order-two-view::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.order-two-view::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.order-two-view::-webkit-scrollbar-thumb {
  background: #00E7FF;
}

/* Handle on hover */
.order-two-view::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.documet-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
    color: #198754 !important;

}
.documet-collapse .ant-collapse{
background-color: #40a9ff3d;
}

.hoastatus{
  margin-left: 4.6px;
  font-weight: 600;
  color: black;
}

.blink-txt {
  animation: blinker 1.5s linear infinite;
  color: green;
  font-weight: 700;
  /* background-color: blue; */
  border-radius: 6px;
  font-size: 10px;
  padding: 3PX;
  position: absolute;
  right: 4rem;
  top: 0.5rem;
}

@keyframes blinker {
  50% {
    scale: 1.3
  }
}

 @media (max-width:1280px) {
  .new-order-editor :where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table-column-title {
    position: relative;
    z-index: 1;
    flex: 1;
    font-size: 9px !important;
}
.new-order-editor .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td{
  font-size: 9px !important;
}
  
} 

.new-order-editor .ant-collapse-content .ant-collapse-content-box {
  padding-bottom: 0px !important;
  padding-top: 5px !important;
  margin-top: 5px !important;
}
