@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;500;900&display=swap");
.app-spinner {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}/*# sourceMappingURL=AppSpinner.css.map */