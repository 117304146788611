@import "../../Style/Variable/Variable.scss";
.order .ant-collapse-header {
  color: #fff !important;
  padding: 4px 15px !important;
}
.order.view-parcel .ant-collapse-header {
  color: #fff !important;
  padding: 0 !important;
}
.order.view-parcel ul li:hover {
  color: #fff !important;
  background-color: $color-primary;
}
.order .ant-collapse-content .ant-collapse-content-box {
  padding-bottom: 0px !important;
  padding-top: 5px !important;
  margin-top: -5px !important;
}
.order .pdf-view {
  width: 300px;
  border: 2px solid green;
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.order .dollar_symobl {
  color: #fff;
  width: 22px;
  height: 22px;
}
.order .ammount .paid {
  color: rgb(81, 231, 81);
}

.order .ant-list-bordered .ant-list-header {
  padding: 0 !important;
}
.order .ant-drawer-body {
  padding: 0 !important;
}
