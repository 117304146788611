.ant-dropdown-menu-item:hover {
  background: #0d86ff;
  color: #FFFFFF;
}

.ant-dropdown-menu-item {
  font-size: 12px;
  color: #777777;
  font-weight: 500;
  transition: 0.25s all ease-in-out;
}

header {
  background: #FFFFFF !important;
}

.avtar {
  width: 29px;
  height: 29px;
}

.avtar-name {
  font-size: 14px;
  color: #777777;
  font-weight: 600;
  text-decoration: none;
}

.ant-dropdown-menu-title-content a {
  text-decoration: none;
}/*# sourceMappingURL=Header.css.map */

.drawer-link {
  transition: background-color 0.2s, color 0.2s;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  font-weight: 500;
  color: #03070e;
  text-decoration: none;
  width: 100%;
  font-size: 14px;
}

.drawer-link:hover {
  background-color: #0d86ff; 
  color: #fff;
}

.drawer-link.active {
  background-color: #222b54;
  color: #fff; 
}

@media (max-width:768px){
  .header-container{
    padding: 0.5rem !important;
  }
  
  
}









